
import CustomTable from '@/common/web/table/index.vue';
import {Options, Vue} from 'vue-class-component';
import {Watch} from 'vue-property-decorator';
import {ILinkMember} from '@/model';
import {Format} from '@/services';
import {ElMessageBox, ElMessage, ElDialog} from 'element-plus';
import wallet from '@/common/web/wallet/wallet.vue';
import {memberListTransfer} from '@/services/server/wap/member-list-transfer';
import {
  IMemberConfigResult,
  IMemberReChargeResult,
  IMemberReChargeRecordResult,
  IMemberReChargeRecord
} from '@/model/member-list-transfer';
import eventBus from '@/web/eventBus';
@Options({
  components: {
    wallet,
    ElMessageBox,
    ElMessage,
    ElDialog,
    CustomTable
  },
  props: {
    item: {
      vip_level: String,
      userName: String,
      level: String,
      levelId: String,
      time: String,
      withdraw: String,
      winLose: String,
      statusText: String,
      registerTime: String,
      lastLoginTime: String,
      depositTime: String
    }
  },
  data() {
    return {};
  },
  computed: {
    table() {
      const list: Array<{
        chargeTime: string;
        amount: string;
        description: string;
      }> = this.showList;
      return {
        list,
        keys: ['chargeTime', 'amount', 'description']
      };
    },
    loadmore() {
      return this.pagination.page * this.pagination.pageLimit >= this.pagination.totalNumber;
    }
  }
})
export default class Login extends Vue {
  amount = '';
  amountList: string[] = [];
  amountTextList = ['100', '500'];
  isConfirm = false;
  minAmount = '';
  maxAmount = '';
  submitLoading = false;
  checkIndex = 1;
  centerWallet = 0;
  today = new Date();
  isLoading = false;
  active = 0;
  showList: IMemberReChargeRecord[] = [];
  list: IMemberReChargeRecord[] = [];
  search: {
    userName: string;
    amount: number;
    description: string;
    startDate: string;
    endDate: string;
  } = {
    userName: '',
    amount: 0,
    description: '',
    startDate: Format.TimeHandle.format(
      new Date(this.today.getFullYear() - 1, this.today.getMonth(), 1),
      'YYYY-MM-DD'
    ),
    endDate: Format.TimeHandle.format(new Date(), 'YYYY-MM-DD')
  };
  modify = {
    // user_id: id,
    user_name: name,
    amount: '',
    description: ''
  };
  // rules = {
  //   username: [{validator: Promoted.validateInput, trigger: 'blur'}],
  //   password: [{validator: Promoted.validateInput, trigger: 'blur'}]
  // };
  titleList = [
    {name: 'rp_transfer_date', key: 'chargeTime'},
    {name: 'rp_transfer_amount', key: 'amount'},
    {name: 'rp_remark', key: 'description'}
  ];
  link: Array<ILinkMember> = [];
  pagination = {
    page: 0,
    pageLimit: 20,
    totalPage: 0,
    totalNumber: 0
  };
  @Watch('checkIndex')
  onCheckIndexChange(val: string) {
    if (val === '2') {
      this.onSearch();
    }
  }

  created() {
    this.init();

    // const {userName} = this.$route.params;
    this.search.userName =
      typeof this.$route.params.userName == 'string' ? this.$route.params.userName : '';
    // this.onSearch();
    eventBus.bus$on('langChange', () => {
      if (this.checkIndex === 2) {
        this.onSearch(this.pagination.page);
      }
    });
  }
  beforeDestroy() {
    eventBus.bus$off('langChange');
  }
  setWallet(wallet: {centerWallet: string}) {
    if (parseInt(wallet.centerWallet.replace(/,/g, '')) < parseInt(this.maxAmount)) {
      this.centerWallet = parseInt(wallet.centerWallet.replace(/,/g, ''));
    } else {
      this.centerWallet = parseInt(this.maxAmount);
    }
  }
  async init() {
    const data = await memberListTransfer.read<IMemberConfigResult>();
    if (data instanceof Error) {
      ElMessage({
        message: data.message
      });
    } else {
      this.amountList = data.fastSelectAmount;
      this.minAmount = data.minAmount;
      this.maxAmount = data.maxAmount;
    }
  }
  transferConfirm() {
    this.isConfirm = true;
    // this.search.userName = name;
  }
  async confirmTransfer() {
    const params = {
      user_name: this.search.userName,
      amount: this.search.amount,
      description: this.search.description
    };
    const data = await memberListTransfer.updated<IMemberReChargeResult>(params);
    if (data instanceof Error) {
      ElMessage({
        message: data.message
      });
      this.isConfirm = false;
    } else {
      ElMessage({
        message: data.message
      });
      this.isConfirm = false;
    }
  }
  inputAmount(amount: number) {
    this.search.amount = amount;
  }
  back() {
    this.$router.push({path: '/userlist'});
  }
  chosenMax() {
    this.search.amount = this.centerWallet;
  }
  async onSearch(val?: {type: string} | number | undefined) {
    // console.log('onSearch val: ', val);
    // this.isLoading = true;
    // if (this.pagination.page >= 1 || this.pagination.totalPage >= this.pagination.page) {
    //   this.search.newTask = false;
    // }
    // if (!val || (typeof val === 'object' && val['type'])) {
    //   this.search.newTask = true;
    //   this.showList = [];
    // }
    // if (this.pagination.page < this.pagination.totalPage) {

    //   this.pagination.page++;
    // }
    //dosearch
    const params = {
      page: this.pagination.page,
      page_limit: this.pagination.pageLimit,
      user_name: this.search.userName,
      start_date: this.search.startDate,
      end_date: this.search.endDate
    };
    const data = await memberListTransfer.detail<IMemberReChargeRecordResult>(params);
    if (data instanceof Error) {
      ElMessage({
        message: data.message
      });
    } else {
      this.list = data.list;
      this.pagination = data.pagination;
      this.showList = this.list;
    }
    setTimeout(() => {
      this.isLoading = false;
    });
  }
}
