import doFetch from '../../fetch';
const AGENTPATH = '/api/agent';
const ACCPATH = '/api/acc/front';

// read: ApiProxy('Users/charge_to_children_range'),
// /** 3.3.3 對直屬下級充值 - 代理前台版本 (https://docs.google.com/document/d/1eB8BNbqhDSnSNdWUOmw0RreNoW-YD9ReeNAC45eCalE/edit#heading=h.5thdpxvn9gjn)
//  * {
//     "user_name": String,
//     "amount": String,
//     "description": String
// }

// */
// updated: ApiFn('recharge/agent_charge_to_children'),
// record: {
//     /**
//      * 36.2.5 用戶轉帳給下級紀錄 (https://docs.google.com/document/d/1T5G_1j0hUUlXm2RHISoqxtc21iWFoeHu03Zq-G28PRs/edit#heading=h.xzmwjjl2apb2)
//      * {
//             "lang": String,
//             "platform_code": String,
//             "page": Integer,
//             "page_limit": Integer,
//             "user_name": String,
//             "user_id": String,
//             "start_date": String,
//             "end_date": String
//         }
//      */
//     read: ApiProxy('Users/charge_to_children_record'),
// },

const actions = {
  read: async <T>() =>
    await doFetch<T>(`${AGENTPATH}/v1/Users/charge_to_children_range`, 'POST', {}),
  updated: async <T>(data: object) =>
    await doFetch<T>(`${ACCPATH}/v1/recharge/agent_charge_to_children`, 'POST', data),
  detail: async <T>(data: object) =>
    await doFetch<T>(`${AGENTPATH}/v1/Users/charge_to_children_record`, 'POST', data)
};

export const memberListTransfer = actions;
