<template>
  <div class="main webstyles">
    <el-card shadow="never" class="cus-card">
      <el-row>
        <el-col :span="24">
          <div class="static-container animated fadeIn info">
            <div class="static-container-header static-container-header--small">
              <h5 class="static-container-header__title">
                <div class="static-container-header__left" @click="back()">
                  <img src="@/assets/web/image/left-arrow.png" alt="" class="c-icons" />
                  {{ $t('ui_transfer') }}
                </div>
                <div class="static-container-header__right"></div>
              </h5>
            </div>
          </div>
        </el-col>
        <div class="form-title">
          <el-radio-group v-model="checkIndex">
            <el-radio-button class="hover" label="1">{{ $t('ui_transfer') }}</el-radio-button>
            <el-radio-button label="2">{{ $t('ui_text_011') }}</el-radio-button>
          </el-radio-group>
        </div>
        <el-col :span="24" v-if="checkIndex == 1">
          <wallet
            @finish-wallet="setWallet"
            :hide="['lock']"
            :showIcon="true"
            :iconColor="'#000'"
            class="text-night background-day u_w--50p"
            style="margin: -15px;"
          />
          <el-form
            class="u_m--t40"
            :model="modelRef"
            :rules="rules"
            ref="formRef"
            label-width="110px"
            label-position="left"
          >
            <el-form-item :label="$t('ui_user_acc_colon')" class="u_w--50p">
              <el-input v-model="search.userName" :disabled="true" />
            </el-form-item>
            <el-form-item :label="$t('rp_transfer_amount') + '：'" class="u_w--50p">
              <el-input v-model="search.amount" :placeholder="$t('ui_enter_amount')" />
              <el-button type="primary" @click="chosenMax" style="margin-left: 10px;">
                {{ $t('ui_maximum') }}
              </el-button>
            </el-form-item>
            <el-form-item>
              <div class="u_t_medium_gray">
                ({{ $t('ui_each_min_money_colon') }} {{ minAmount }} ,
                {{ $t('ui_each_max_money_colon') }} {{ maxAmount }})
              </div>
            </el-form-item>
            <el-form-item>
              <div
                v-for="i in amountList"
                @click="inputAmount(i)"
                :key="i"
                class="tab_title_btn"
                :class="[{tab_title_btn_active: +search.amount === +i}]"
              >
                <span>
                  {{ i }}
                  <!-- {{ amountTextList[id] || i }} -->
                </span>
              </div>
            </el-form-item>
            <el-form-item :label="$t('ui_remarks') + '：'" class="u_w--50p">
              <el-input
                v-model="search.description"
                :placeholder="$t('ui_remarks')"
                show-word-limit
                maxlength="8"
              />
            </el-form-item>
            <el-form-item class="u_w--50p">
              <el-button
                type="primary"
                @click="transferConfirm"
                :disabled="search.amount < minAmount - 1"
              >
                <!-- {{search.amount > minAmount}} -->
                {{ $t('btn_next') }}
              </el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-dialog
        round
        :close-on-popstate="true"
        :closeable="true"
        v-model="isConfirm"
        :title="$t('ui_wap_text_044')"
      >
        <el-form ref="form" :model="form" label-width="135px" class="u_m--auto" style="width: 60%;">
          <el-form-item :label="$t('ui_user_acc_colon')" class="u_w--50p">
            {{ search.userName }}
          </el-form-item>
          <el-form-item :label="$t('rp_transfer_amount') + '：'" class="u_w--50p">
            {{ search.amount }}
          </el-form-item>
          <el-form-item :label="$t('ui_remarks') + '：'" class="u_w--50p">
            {{ search.description }}
          </el-form-item>
          <div class="u_m--auto u_w--50p">
            <el-button @click="isConfirm = false">{{ $t('btn_back') }}</el-button>
            <el-button type="primary" @click="confirmTransfer">{{ $t('btn_confirm') }}</el-button>
          </div>
        </el-form>
      </el-dialog>
      <div v-if="checkIndex == 2">
        <div>{{ $route.params.userName }}&nbsp; {{ $t('ui_text_011') }}</div>
        <div class=" j_txt--red tips">
          *{{ $t('ui_total_time_colon').replace('：', '') }}：{{ search.startDate }} -
          {{ search.endDate }}
        </div>
        <div class="u-m__t40">
          <custom-table
            :titleList="titleList"
            :list="showList"
            :table="table"
            :pagination="pagination"
            @change="handleChange"
            :loading="tableLoading"
          >
          </custom-table>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script lang="ts">
import CustomTable from '@/common/web/table/index.vue';
import {Options, Vue} from 'vue-class-component';
import {Watch} from 'vue-property-decorator';
import {ILinkMember} from '@/model';
import {Format} from '@/services';
import {ElMessageBox, ElMessage, ElDialog} from 'element-plus';
import wallet from '@/common/web/wallet/wallet.vue';
import {memberListTransfer} from '@/services/server/wap/member-list-transfer';
import {
  IMemberConfigResult,
  IMemberReChargeResult,
  IMemberReChargeRecordResult,
  IMemberReChargeRecord
} from '@/model/member-list-transfer';
import eventBus from '@/web/eventBus';
@Options({
  components: {
    wallet,
    ElMessageBox,
    ElMessage,
    ElDialog,
    CustomTable
  },
  props: {
    item: {
      vip_level: String,
      userName: String,
      level: String,
      levelId: String,
      time: String,
      withdraw: String,
      winLose: String,
      statusText: String,
      registerTime: String,
      lastLoginTime: String,
      depositTime: String
    }
  },
  data() {
    return {};
  },
  computed: {
    table() {
      const list: Array<{
        chargeTime: string;
        amount: string;
        description: string;
      }> = this.showList;
      return {
        list,
        keys: ['chargeTime', 'amount', 'description']
      };
    },
    loadmore() {
      return this.pagination.page * this.pagination.pageLimit >= this.pagination.totalNumber;
    }
  }
})
export default class Login extends Vue {
  amount = '';
  amountList: string[] = [];
  amountTextList = ['100', '500'];
  isConfirm = false;
  minAmount = '';
  maxAmount = '';
  submitLoading = false;
  checkIndex = 1;
  centerWallet = 0;
  today = new Date();
  isLoading = false;
  active = 0;
  showList: IMemberReChargeRecord[] = [];
  list: IMemberReChargeRecord[] = [];
  search: {
    userName: string;
    amount: number;
    description: string;
    startDate: string;
    endDate: string;
  } = {
    userName: '',
    amount: 0,
    description: '',
    startDate: Format.TimeHandle.format(
      new Date(this.today.getFullYear() - 1, this.today.getMonth(), 1),
      'YYYY-MM-DD'
    ),
    endDate: Format.TimeHandle.format(new Date(), 'YYYY-MM-DD')
  };
  modify = {
    // user_id: id,
    user_name: name,
    amount: '',
    description: ''
  };
  // rules = {
  //   username: [{validator: Promoted.validateInput, trigger: 'blur'}],
  //   password: [{validator: Promoted.validateInput, trigger: 'blur'}]
  // };
  titleList = [
    {name: 'rp_transfer_date', key: 'chargeTime'},
    {name: 'rp_transfer_amount', key: 'amount'},
    {name: 'rp_remark', key: 'description'}
  ];
  link: Array<ILinkMember> = [];
  pagination = {
    page: 0,
    pageLimit: 20,
    totalPage: 0,
    totalNumber: 0
  };
  @Watch('checkIndex')
  onCheckIndexChange(val: string) {
    if (val === '2') {
      this.onSearch();
    }
  }

  created() {
    this.init();

    // const {userName} = this.$route.params;
    this.search.userName =
      typeof this.$route.params.userName == 'string' ? this.$route.params.userName : '';
    // this.onSearch();
    eventBus.bus$on('langChange', () => {
      if (this.checkIndex === 2) {
        this.onSearch(this.pagination.page);
      }
    });
  }
  beforeDestroy() {
    eventBus.bus$off('langChange');
  }
  setWallet(wallet: {centerWallet: string}) {
    if (parseInt(wallet.centerWallet.replace(/,/g, '')) < parseInt(this.maxAmount)) {
      this.centerWallet = parseInt(wallet.centerWallet.replace(/,/g, ''));
    } else {
      this.centerWallet = parseInt(this.maxAmount);
    }
  }
  async init() {
    const data = await memberListTransfer.read<IMemberConfigResult>();
    if (data instanceof Error) {
      ElMessage({
        message: data.message
      });
    } else {
      this.amountList = data.fastSelectAmount;
      this.minAmount = data.minAmount;
      this.maxAmount = data.maxAmount;
    }
  }
  transferConfirm() {
    this.isConfirm = true;
    // this.search.userName = name;
  }
  async confirmTransfer() {
    const params = {
      user_name: this.search.userName,
      amount: this.search.amount,
      description: this.search.description
    };
    const data = await memberListTransfer.updated<IMemberReChargeResult>(params);
    if (data instanceof Error) {
      ElMessage({
        message: data.message
      });
      this.isConfirm = false;
    } else {
      ElMessage({
        message: data.message
      });
      this.isConfirm = false;
    }
  }
  inputAmount(amount: number) {
    this.search.amount = amount;
  }
  back() {
    this.$router.push({path: '/userlist'});
  }
  chosenMax() {
    this.search.amount = this.centerWallet;
  }
  async onSearch(val?: {type: string} | number | undefined) {
    // console.log('onSearch val: ', val);
    // this.isLoading = true;
    // if (this.pagination.page >= 1 || this.pagination.totalPage >= this.pagination.page) {
    //   this.search.newTask = false;
    // }
    // if (!val || (typeof val === 'object' && val['type'])) {
    //   this.search.newTask = true;
    //   this.showList = [];
    // }
    // if (this.pagination.page < this.pagination.totalPage) {

    //   this.pagination.page++;
    // }
    //dosearch
    const params = {
      page: this.pagination.page,
      page_limit: this.pagination.pageLimit,
      user_name: this.search.userName,
      start_date: this.search.startDate,
      end_date: this.search.endDate
    };
    const data = await memberListTransfer.detail<IMemberReChargeRecordResult>(params);
    if (data instanceof Error) {
      ElMessage({
        message: data.message
      });
    } else {
      this.list = data.list;
      this.pagination = data.pagination;
      this.showList = this.list;
    }
    setTimeout(() => {
      this.isLoading = false;
    });
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/styles/element.scss';
.form-title {
  margin-bottom: 30px;
}
.u_w--50p {
  width: 50%;
}
.u_m--auto {
  margin: 0 auto;
}
.u_p--l10 {
  padding-left: 10px;
}
.u_p--r10 {
  padding-right: 10px;
}
.main-btn {
  width: 100%;
  margin: auto;
  height: 1.92rem;
  color: $cf;
  background-color: $bg;
  border-radius: 8px;
  font-size: 0.64rem;
  border-width: 0;
}
.static-container-header {
  margin-bottom: 30px;
}
.static-container-header--small .static-container-header__title {
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: capitalize;
}
.static-container-header__left {
  display: flex;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
}
> :deep(.el-form-item__content) {
  display: flex;
}
.u_m--t40 {
  margin-top: 40px;
}
.u_m--b40 {
  margin-bottom: 40px;
}
.tab_title_btn {
  padding: 0px 20px 0px 20px;
  line-height: 26px;
  border: 1px solid $bg;
  margin-right: 10px;
  border-radius: 3px;
}
.tab_title_btn_active {
  background-color: $bg;
  color: $cf;
  margin-right: 10px;
}
:deep(.el-dialog__body) {
  padding: 0 20px 30px;
}
.hover :deep(.el-radio-button__inner):hover {
  color: #606266;
}
.j_txt--red {
  color: red;
}
.tips {
  margin: 20px 0;
}
:deep(.j_bg--primary) {
  background-color: #fff;
  color: #000;
}
:deep(.j_txt--base) {
  color: #000;
}
:deep(.el-form-item__label) {
  text-align: left;
}
</style>
